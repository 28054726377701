.App {
  text-align: center;
  background-color: white; /* Set the background color to white */
  color: black; /* Set the text color to black */
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.card-container {
  display: flex;
  flex-wrap: wrap; /* Allow the cards to wrap to a new line */
  justify-content: space-between;
}

.card {
  border: 1px solid blue;
  padding: 20px;
  width: 300px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  /* Media query for small screens (e.g., mobile devices) */
  .card {
    width: 100%; /* Set the width to 100% to occupy the full width of the container */
  }
}
